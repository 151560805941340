import '@/assets/less/data_flex.less'
import '@/assets/less/icons.less'
import '@/assets/less/nec_funcs.less'
import '@/assets/less/nec_reset.less'
import app from '@/views/app/main'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { datetimeFormat } from './filters/datetime_format'
import { qiniuImageView } from './filters/qiniu_imageview'
import { qiniuResource } from './filters/qiniu_resource'
import { strTrim } from './filters/str_trim'
import { strTrimLeft } from './filters/str_trim_left'
import { strTrimRight } from './filters/str_trim_right'
import { toDecimalString } from './filters/to_decimal_string'
import router from './router/router'
import httpFun, { apiPost } from './utils/request'
import isAssert, { isEmpty } from './utils/assert'
import getCommon, { buildApiResult } from './utils/common'
import { defaultPageTitle, defaultPageBackgroundColor, wxgzhAuthorizeUrl, baseUrl } from '@/config/common'
import { wxuserMock, mwebBaseUrl } from './config/common'
import { localStorageGet, localStorageSet } from './utils/storage'
import { cacheKeys } from './config/CacheKeys'

// import CanvasPoster from 'vue-canvas-poster'

// Vue.use(CanvasPoster)

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.use(VueRouter)


Vue.filter('qiniuResource', qiniuResource)
Vue.filter('qiniuImageView', qiniuImageView)
Vue.filter('toDecimalString', toDecimalString)
Vue.filter('datetimeFormat', datetimeFormat)
Vue.filter('strTrim', strTrim)
Vue.filter('strTrimLeft', strTrimLeft)
Vue.filter('strTrimRight', strTrimRight)


for (let fn in httpFun) {
    Vue.prototype[fn] = httpFun[fn]
}
for (let fn in isAssert) {
    Vue.prototype[fn] = isAssert[fn]
}
for (let fn in getCommon) {
    Vue.prototype[fn] = getCommon[fn]
}

// router.beforeEach(async (to, from, next) => {
//     console.log(mwebBaseUrl + baseUrl + to.fullPath)
//     console.log("默认请求", to)
//     const matched = to.matched.some(record => record.meta && record.meta.needWxuser === false)

//     if (matched) {
//         next()
//         return
//     }

//     if (to.query.code) {
//         next()
//         return
//     }

//     const jwt = localStorageGet(cacheKeys.jwt)
//     const wxuser = localStorageGet(cacheKeys.wxuser)

//     if (jwt && jwt.token && wxuser && wxuser.memberId > 0) {
//         next()
//         return
//     }

//     if (wxuserMock.enable && wxuserMock.data.memberId > 0) {
//         if (!jwt || !jwt.token) {
//             const { memberId, openid } = wxuserMock.data
//             let apiResult

//             try {
//                 apiResult = await apiPost('wxgzh.jwtmock', { memberId, openid })
//             } catch (ex) {
//                 apiResult = buildApiResult()
//             }

//             if (!isEmpty(apiResult.obj)) {
//                 localStorageSet(cacheKeys.jwt, apiResult.obj)
//             }
//         }

//         localStorageSet(cacheKeys.wxuser, wxuserMock.data)
//         next()
//         return
//     }

//     const redirectUrl = mwebBaseUrl + baseUrl + to.fullPath
//     // const redirectUrl = mwebBaseUrl + baseUrl + to.path

//     const sb = [
//         wxgzhAuthorizeUrl,
//         '?authorizeRedirectUrl=' + encodeURIComponent(redirectUrl)
//     ]
//     // console.log(sb.join(''))
//     // return

//     window.location.href = sb.join('')
//     next(false)
// })

router.afterEach(to => {
    const { pageTitle, bgColor } = to.meta || {}
    document.title = pageTitle || defaultPageTitle
    document.getElementsByTagName('body')[0].style.backgroundColor = bgColor || defaultPageBackgroundColor
})

const vueApp = new Vue({
    el: '#app',
    router,
    render: h => h(app)
})

export default vueApp