import {isArray, isEmpty, isNumber, isString} from './assert'
import {getRandomNumber} from './number'

/**
 * @param str
 * @param chars
 * @param isRegexp
 * @returns {string}
 */
export const strTrimLeft = (str, chars = " \t\n\r\0\x0B", isRegexp = true) => {
    const regexp = isRegexp ? new RegExp(`^[${chars}]+`) : chars
    return str.replace(regexp, '')
}

/**
 * @param str
 * @param chars
 * @param isRegexp
 * @returns {string}
 */
export const strTrimRight = (str, chars = " \t\n\r\0\x0B", isRegexp = true) => {
    const regexp = isRegexp ? new RegExp(`[${chars}]+$`) : chars
    return str.replace(regexp, '')
}

/**
 * @param str
 * @param chars
 * @param isRegexp
 * @returns {string}
 */
export const strTrim = (str, chars = " \t\n\r\0\x0B", isRegexp = true) => {
    return strTrimRight(strTrimLeft(str, chars, isRegexp), chars, isRegexp)
}

/**
 * @param str
 * @param chars
 * @returns {string[]}
 */
export const splitByChars = (str, chars) => {
    if (!isString(str) || str === '') {
        return []
    }

    if (!isArray(chars)) {
        return [str]
    }

    chars = chars.filter(it => {
        return isString(it) && it.length === 1
    })

    if (isEmpty(chars)) {
        return [str]
    }

    str = str.replace(/^[\x20\t]+/, '')
    str = str.replace(/[\x20\t]+$/, '')

    for (let ch of chars) {
        str = str.replace(new RegExp(`[${ch}]+`, 'g'), ch)
    }

    const positions = []

    for (let i = 0; i < str.length; i++) {
        if (!chars.includes(str.charAt(i))) {
            continue
        }

        positions.push(i)
    }

    if (isEmpty(positions)) {
        return [str]
    }

    const parts = []
    let n1 = 0
    const lastPos = [...positions].pop()

    for (let pos of positions) {
        let p

        try {
            p = pos === lastPos ? str.substring(pos + 1) : str.substring(n1, pos)
        } catch (ex) {
            p = null
        }

        n1 = pos + 1

        if (p) {
            parts.push(p)
        }
    }

    return parts
}

/**
 * @param str
 * @param delimiter
 * @returns {string}
 */
export const substringBefore = (str, delimiter) => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    if (!isString(delimiter) || isEmpty(delimiter)) {
        return str
    }

    const idx = str.indexOf(delimiter)

    if (idx < 0) {
        return str
    }

    if (idx === 0) {
        return ''
    }

    return str.substring(0, idx)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param str
 * @param delimiter
 * @returns {string}
 */
export const substringBeforeLast = (str, delimiter) => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    if (!isString(delimiter) || isEmpty(delimiter)) {
        return str
    }

    const idx = str.lastIndexOf(delimiter)

    if (idx < 0) {
        return str
    }

    if (idx === 0) {
        return ''
    }

    return str.substring(0, idx)
}

/**
 * @param str
 * @param delimiter
 * @returns {string}
 */
export const substringAfter = (str, delimiter) => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    if (!isString(delimiter) || isEmpty(delimiter)) {
        return str
    }

    const idx = str.indexOf(delimiter)

    if (idx < 0) {
        return str
    }

    return str.substring(idx + 1)
}

/**
 * @param str
 * @param delimiter
 * @returns {string}
 */
export const substringAfterLast = (str, delimiter) => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    if (!isString(delimiter) || isEmpty(delimiter)) {
        return str
    }

    const idx = str.lastIndexOf(delimiter)

    if (idx < 0) {
        return str
    }

    return str.substring(idx + 1)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param len
 * @param type
 * @returns {string}
 */
export const getRandomString = (len, type) => {
    if (!isNumber(type) || ![1, 2, 3].includes(type)) {
        type = 1
    }

    const seeds = [
        '',
        'abcdefghijklmnopqrstuvwxyz0123456789',
        'abcdefghijklmnopqrstuvwxyz',
        '0123456789'
    ][type]

    const sb = []

    for (let i = 1; i <= len; i++) {
        const idx = getRandomNumber(1, seeds.length - 1)
        sb.push(seeds.charAt(idx))
    }

    return sb.join('')
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param str
 * @returns {string}
 */
export const maskEmail = str => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    let p1 = substringBefore(str, '@')
    const p2 = substringAfter(str, '@')
    const n1 = p1.length

    if (n1 <= 4) {
        p1 = maskString(p1, 1, 1)
    } else if (n1 > 4 && n1 <= 6) {
        p1 = maskString(p1, 2, 2)
    } else {
        p1 = maskString(p1, 3, 3)
    }

    return `${p1}@${p2}`
}

/**
 * @param str
 * @param prefixLen
 * @param suffixLen
 * @returns {string}
 */
export const maskString = (str, prefixLen, suffixLen) => {
    if (!isString(str) || isEmpty(str) || !isNumber(prefixLen) || prefixLen < 1) {
        return str
    }

    if (!isNumber(suffixLen) || suffixLen < 0) {
        suffixLen = 0
    }

    if (str.length <= prefixLen + suffixLen) {
        return str
    }

    const p1 = str.substring(0, prefixLen)
    const p2 = suffixLen > 0 ? str.substring(str.length - suffixLen) : ''
    const p3 = '*'.repeat(str.length - prefixLen - suffixLen)
    return `${p1}${p2}${p3}`
}

/**
 * @param str
 * @returns {string}
 */
export const lcfirst = str => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    return `${str.charAt(0).toLowerCase()}${str.substring(1)}`
}

/**
 * @param str
 * @returns {string}
 */
export const ucfirst = str => {
    if (!isString(str) || isEmpty(str)) {
        return str
    }

    return `${str.charAt(0).toUpperCase()}${str.substring(1)}`
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param str
 * @param joinBy
 * @param delimiters
 * @returns {string}
 */
export const lcwords = (str, joinBy, delimiters) => {
    if (!isString(str) || isEmpty(str) || !isArray(delimiters)) {
        return str
    }

    delimiters = delimiters.filter(it => {
        return isString(it) && it.length === 1
    })

    if (isEmpty(delimiters)) {
        return str
    }

    const parts = splitByChars(str, delimiters).map(it => {
        return lcfirst(it)
    })

    if (!isString(joinBy)) {
        joinBy = ''
    }

    return parts.join(joinBy)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param str
 * @param joinBy
 * @param delimiters
 * @returns {string}
 */
export const ucwords = (str, joinBy, delimiters) => {
    if (!isString(str) || isEmpty(str) || !isArray(delimiters)) {
        return str
    }

    delimiters = delimiters.filter(it => {
        return isString(it) && it.length === 1
    })

    if (isEmpty(delimiters)) {
        return str
    }

    const parts = splitByChars(str, delimiters).map(it => {
        return ucfirst(it)
    })

    if (!isString(joinBy)) {
        joinBy = ''
    }

    return parts.join(joinBy)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param str
 * @param search
 * @returns {string}
 */
export const ensureLeft = (str, search) => {
    if (!isString(str) || !isString(search) || isEmpty(search)) {
        return str
    }

    return str.startsWith(search) ? str : `${search}${str}`
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param str
 * @param search
 * @returns {string}
 */
export const ensureRight = (str, search) => {
    if (!isString(str) || !isString(search) || isEmpty(search)) {
        return str
    }

    return str.endsWith(search) ? str : `${str}${search}`
}