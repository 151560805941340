import axios from 'axios'
import qs from 'qs'
import cfg from '../config/api_config'
import { isApiResult, isArray, isEmpty, isObject, isString, isVue } from './assert'
import { buildApiResult } from './common'
import { localStorageGet } from '@/utils/storage'
import { cacheKeys } from '@/config/CacheKeys'

const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest'
}

/**
 * @param configKey
 * @param opts
 * @returns {string}
 */
function buildRequestUrl(configKey, opts) {
    let requestUrl = cfg[configKey].url
    const pathVariables = opts.pathVariables || {}
    const queryParams = opts.queryParams || {}

    requestUrl = requestUrl.replace('http://', '[http]')
    requestUrl = requestUrl.replace('https://', '[https]')

    if (!isEmpty(pathVariables)) {
        const parts = requestUrl.split('/')
        const sb = []

        for (let part of parts) {
            if (isEmpty(part)) {
                continue
            }

            if (!part.startsWith('{') || !part.endsWith('}')) {
                sb.push(part)
            }

            const pathVariableName = part.replace(/^{/, '').replace(/}$/, '')

            if (pathVariableName in pathVariables) {
                sb.push(pathVariables[pathVariableName])
            }
        }

        requestUrl = `/${sb.join('/')}`
    }

    if (!isEmpty(queryParams)) {
        requestUrl += (requestUrl.includes('?') ? '&' : '?') + qs.stringify(queryParams)
    }

    if (!requestUrl.includes('[http]') && !requestUrl.includes('[https]')) {
        requestUrl = `${cfg.prefix}${requestUrl}`
    }

    requestUrl = requestUrl.replace(/[\/]+/g, '/')
    requestUrl = requestUrl.replace('[http]', '')
    requestUrl = requestUrl.replace('[https]', '')
    return requestUrl
}

/**
 * @param opts
 * @returns {Promise<*>}
 */
async function sendRequest(opts) {
    const configKey = opts.configKey || ''

    if (!configKey) {
        return Promise.resolve(buildApiResult(
            cfg.errno.apiCallException,
            '无法加载api配置'
        ))
    }

    let apiConfig

    try {
        apiConfig = cfg[configKey]
    } catch (ex) {
        apiConfig = {}
    }

    const { url } = apiConfig

    if (!url) {
        return Promise.resolve(buildApiResult(
            cfg.errno.apiCallException,
            '无法加载api配置'
        ))
    }

    const pathVariables = opts.pathVariables || {}
    const queryParams = opts.queryParams || {}
    const requestUrl = buildRequestUrl(configKey, { pathVariables, queryParams })
    const headers = !isEmpty(opts.headers) ? { ...opts.headers, ...defaultHeaders } : defaultHeaders
    const jwt = localStorageGet(cacheKeys.jwt)
    if (jwt && jwt.token) {
        headers.Authorization = `JWT ${jwt.token}`
    }

    // headers.Authorization = `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJoanh0Lnd4YXBwIiwiaWF0IjoxNjY3NDQ3MDg4LCJleHAiOjE3OTE4NjMwODgsIm1lbWJlcklkIjo1MDAwMiwib3BlbmlkIjoib2wzSGk1YktZYW9FR1dNSW9TT0hQRWc1MUQ3ZzIiLCJ1bmlvbmlkIjoiIn0.EBJfl9aw14tL2yEAStfbgHJMohtVR3RO5RGdSTmG9ol1HbBSEWaChn6pCwhr6jEKYvaeGe5mPsjXFrbIzvNw6ncxQkSTXZKtUZOPibOu5_r1rYXeXYM0Riqc4GOHhwnZVtVF6PuhTWFgSnRdiB5RZpbwKbVTioRZEbfkGi0gY-8`
    console.log('requestUrl', requestUrl)
    const axiosOptions = {
        url: requestUrl,
        responseType: 'json',
        timeout: cfg.readTimeout
    }

    switch (opts.action) {
        case 'get':
            delete headers['Content-Type']
            axiosOptions.method = 'GET'
            axiosOptions.headers = headers
            break
        case 'post':
            headers['Content-Type'] = 'application/x-www-form-urlencoded'
            axiosOptions.method = 'POST'
            axiosOptions.headers = headers

            if (isObject(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = qs.stringify(opts.requestBody)
            } else if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'postJson':
            headers['Content-Type'] = 'application/json'
            axiosOptions.method = 'POST'
            axiosOptions.headers = headers

            if ((isObject(opts.requestBody) || isArray(opts.requestBody)) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = JSON.stringify(opts.requestBody)
            } else if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'postXml':
            headers['Content-Type'] = 'application/xml'
            axiosOptions.method = 'POST'
            axiosOptions.headers = headers

            if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'put':
        case 'putJson':
            headers['Content-Type'] = 'application/json'
            axiosOptions.method = 'PUT'
            axiosOptions.headers = headers

            if ((isObject(opts.requestBody) || isArray(opts.requestBody)) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = JSON.stringify(opts.requestBody)
            } else if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'putXml':
            headers['Content-Type'] = 'application/xml'
            axiosOptions.method = 'PUT'
            axiosOptions.headers = headers

            if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'patch':
        case 'patchJson':
            headers['Content-Type'] = 'application/json'
            axiosOptions.method = 'PATCH'
            axiosOptions.headers = headers

            if ((isObject(opts.requestBody) || isArray(opts.requestBody)) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = JSON.stringify(opts.requestBody)
            } else if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'patchXml':
            headers['Content-Type'] = 'application/xml'
            axiosOptions.method = 'PATCH'
            axiosOptions.headers = headers

            if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'delete':
            delete headers['Content-Type']
            axiosOptions.method = 'DELETE'
            axiosOptions.headers = headers
            break
        case 'deleteWithJson':
            headers['Content-Type'] = 'application/json'
            axiosOptions.method = 'DELETE'
            axiosOptions.headers = headers

            if ((isObject(opts.requestBody) || isArray(opts.requestBody)) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = JSON.stringify(opts.requestBody)
            } else if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
        case 'deleteWithXml':
            headers['Content-Type'] = 'application/xml'
            axiosOptions.method = 'DELETE'
            axiosOptions.headers = headers

            if (isString(opts.requestBody) && !isEmpty(opts.requestBody)) {
                axiosOptions.data = opts.requestBody
            }

            break
    }
    console.log('axiosOptions', axiosOptions)
    return new Promise(resolve => {
        try {
            axios(axiosOptions).then(result => {
                const apiResult = parseAxiosResult(result)

                if (!isObject(apiResult)) {
                    resolve(buildApiResult(cfg.errno.apiCallException, '接口请求出错'))
                    return
                }

                resolve(apiResult)
            }).catch(() => resolve(buildApiResult(cfg.errno.apiCallException, '接口返回值解析出错')))
        } catch (ex) {
            resolve(buildApiResult(cfg.errno.apiCallException, '接口请求出错'))
        }
    })
}

/**
 * @param result
 * @returns {{}|null}
 */
function parseAxiosResult(result) {
    if (!isObject(result) || result.status !== 200) {
        return buildApiResult(cfg.errno.apiCallException)
    }

    let { data } = result

    if (isString(data)) {
        try {
            data = JSON.parse(data)
        } catch (ex) {
            data = null
        }
    }

    return isApiResult(data) ? data : buildApiResult(cfg.errno.apiCallException)
}

/**
 * @param action
 * @param args
 * @returns {[]}
 */
function buildRequestParams(action, args) {
    if (isVue(args[0])) {
        args.shift()
    }

    const configKey = args.shift()
    let needPathVariables

    try {
        needPathVariables = cfg[configKey].url.indexOf('/{') !== -1
    } catch (ex) {
        needPathVariables = false
    }

    let pathVariables = null
    let queryParams = null
    let requestBody = null

    switch (action) {
        case 'get':
            for (let arg of args) {
                isObject(arg) && !isEmpty(arg) && queryParams === null ? queryParams = arg : false
            }

            return [configKey, queryParams]
        case 'post':
            for (let arg of args) {
                isObject(arg) && !isEmpty(arg) && requestBody === null ? requestBody = arg : false
            }

            return [configKey, requestBody]
        case 'postJson':
        case 'postXml':
            for (let arg of args) {
                !isEmpty(arg) && requestBody === null ? requestBody = arg : false
            }

            return [configKey, requestBody]
        case 'put':
        case 'putJson':
        case 'putXml':
        case 'patch':
        case 'patchJson':
        case 'patchXml':
        case 'deleteWithJson':
        case 'deleteWithXml':
            for (let arg of args) {
                if (isObject(arg)) {
                    if (isEmpty(arg)) {
                        continue
                    }

                    if (needPathVariables && pathVariables === null) {
                        pathVariables = arg
                    } else if (requestBody === null) {
                        requestBody = arg
                    }

                    continue
                }

                !isEmpty(arg) && requestBody === null ? requestBody = arg : false
            }

            return [configKey, pathVariables, requestBody]
        case 'delete':
            for (let arg of args) {
                isObject(arg) && !isEmpty(arg) && needPathVariables && pathVariables === null ? pathVariables = arg : false
            }

            return [configKey, pathVariables]
        default:
            return [configKey, null, null, null]
    }
}

/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiGet = (...args) => {
    const [configKey, queryParams] = buildRequestParams('get', args)
    return sendRequest({ action: 'get', configKey, queryParams })
}

/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPost = (...args) => {
    const [configKey, requestBody] = buildRequestParams('post', args)
    return sendRequest({ action: 'post', configKey, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPostJson = (...args) => {
    const [configKey, requestBody] = buildRequestParams('postJson', args)
    return sendRequest({ action: 'postJson', configKey, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPostXml = (...args) => {
    const [configKey, requestBody] = buildRequestParams('postXml', args)
    return sendRequest({ action: 'postXml', configKey, requestBody })
}

/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPut = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('put', args)
    return sendRequest({ action: 'put', configKey, pathVariables, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPutJson = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('putJson', args)
    return sendRequest({ action: 'putJson', configKey, pathVariables, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPutXml = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('putXml', args)
    return sendRequest({ action: 'putXml', configKey, pathVariables, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPatch = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('patch', args)
    return sendRequest({ action: 'patch', configKey, pathVariables, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPatchJson = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('patchJson', args)
    return sendRequest({ action: 'patchJson', configKey, pathVariables, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiPatchXml = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('patchXml', args)
    return sendRequest({ action: 'patchXml', configKey, pathVariables, requestBody })
}

/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiDelete = (...args) => {
    const [configKey, pathVariables] = buildRequestParams('delete', args)

    return sendRequest({ action: 'delete', configKey, pathVariables })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiDeleteWithJson = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('deleteWithJson', args)
    return sendRequest({ action: 'deleteWithJson', configKey, pathVariables, requestBody })
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {Promise<*>}
 */
export const apiDeleteWithXml = (...args) => {
    const [configKey, pathVariables, requestBody] = buildRequestParams('deleteWithXml', args)
    return sendRequest({ action: 'deleteWithXml', configKey, pathVariables, requestBody })
}


export default {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
}