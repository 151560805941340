let prefix

// noinspection JSUnresolvedVariable
switch (process.env.VUE_APP_MODE) {
    case 'prod':
        prefix = '/wxapp-api'
        break
    case 'ppe':
        prefix = '/api'
        break
    default:
        prefix = '/api'
        break
}

// noinspection JSUnusedGlobalSymbols
export const apiPrefix = prefix

export default {
    /* api 接口前缀 */
    prefix,
    /* api 接口超时时间 */
    readTimeout: 60 * 1000 * 10,
    /* 安全令牌类型 */
    tokenType: 'mweb',
    /* api 接口返回的错误码 */
    errno: {
        /* 需要安全令牌 */
        requireAccessToken: 1001,
        /* 不是有效的安全临牌 */
        invalidAccessToken: 1002,
        /* 安全令牌已失效 */
        accessTokenExpired: 1003,
        /* 数据完整性验证错误 */
        dataValidationError: 1006,
        /* 接口请求出错 */
        apiCallException: -1,
        /* 接口数据解析异常 */
        apiResultParseException: -2,
        /* token刷新失败 */
        tokenRefreshException: -3
    },

    /* 接口列表 */
    'jwt.refresh': {
        url: '/common/token/refresh',
        withoutJwt: true
    },
    /* 图片识别 */
    'goods.image': {
        url: '/wxapp/goods/image',
        withoutJwt: true
    },
    /* 商品列表（关键字搜索/全部商品） */
    'goods.list': {
        url: '/wxapp/goods',
        withoutJwt: true
    },
    /* 产品详情 */
    'goods.detail': {
        url: '/wxapp/goods/detail',
        withoutJwt: true
    },
    /* 商品分类 */
    'categories.list': {
        url: '/wxapp/goods/categories',
        withoutJwt: true
    },
    /* 上传图片 */
    'image.save': {
        url: '/wxapp/image/save',
        withoutJwt: true
    }
}