export default [
    {
        path: '/',
        name: 'HomeIndex',
        meta: {
            pageTitle: '名富实业',
            bgColor: '#f8f8f8'
        },
        component: () => import('@/views/home/index')
    },
    {
        path: '/home/camera',
        name: 'homeCamera',
        meta: {
            pageTitle: '相机',
            bgColor: '#f8f8f8'
        },
        component: () => import('@/views/home/camera')
    },
    {
        path: '/goods/list',
        name: 'goodsList',
        meta: {
            pageTitle: '全部商品',
            bgColor: '#f8f8f8'
        },
        component: () => import('@/views/goods/list')
    },
    {
        path: '/goods/detail',
        name: 'goodsDetail',
        meta: {
            pageTitle: '商品详情',
            bgColor: '#f8f8f8'
        },
        component: () => import('@/views/goods/detail')
    },
    {
        path: '/goods/search',
        name: 'goodsSearch',
        meta: {
            pageTitle: '搜索结果',
            bgColor: '#f8f8f8'
        },
        component: () => import('@/views/goods/search')
    },
    {
        path: '/goods/searchRecognition',
        name: 'goodsSearchRecognition',
        meta: {
            pageTitle: '识别结果',
            bgColor: '#f8f8f8'
        },
        component: () => import('@/views/goods/search-recognition')
    },
    
    

]