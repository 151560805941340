import customEvents from '../config/custom_events'
import bus from '../libs/bus'
import {isFunction, isObject, isString} from './assert'

// noinspection JSUnusedGlobalSymbols
/**
 * @param opts
 */
export const uiShowLoading = (opts = {}) => {
    bus.$emit(customEvents['loading.show'], opts)
}

// noinspection JSUnusedGlobalSymbols
export const uiHideLoading = () => {
    bus.$emit(customEvents['loading.hide'])
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param tips
 * @param args
 */
export const uiAlert = (tips, ...args) => {
    let opts = null
    let btnCallback = null

    for (let arg of args) {
        if (isFunction(arg)) {
            btnCallback === null ? btnCallback = arg : false
            continue
        }

        if (isObject(arg)) {
            opts === null ? opts = arg : false
        }
    }

    if (opts === null) {
        opts = {}
    }

    bus.$emit(customEvents['AlertDialog.show'], {...opts, tips, btnCallback})
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param tips
 * @param args
 */
export const uiConfirm = (tips, ...args) => {
    let opts = null
    let btn1Callback = null
    let btn2Callback = null

    for (let arg of args) {
        if (isFunction(arg)) {
            if (btn1Callback === null) {
                btn1Callback = arg
            } else if (btn2Callback === null) {
                btn2Callback = arg
            }

            continue
        }

        if (isObject(arg)) {
            opts === null ? opts = arg : false
        }
    }

    if (opts === null) {
        opts = {}
    }

    bus.$emit(customEvents['ConfirmDialog.show'], {...opts, tips, btn1Callback, btn2Callback})
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 */
export const uiShowSuccessTips = (...args) => {
    let tips = null
    let callback = null

    for (let arg of args) {
        if (isString(arg)) {
            arg !== '' && tips === null ? tips = arg : false
            continue
        }

        if (isFunction(arg) && callback === null) {
            callback = arg
        }
    }

    uiAlert(tips || '操作成功', callback)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 */
export const uiShowErrorTips = (...args) => {
    let tips = null
    let callback = null

    for (let arg of args) {
        if (isString(arg)) {
            arg !== '' && tips === null ? tips = arg : false
            continue
        }

        if (isFunction(arg) && callback === null) {
            callback = arg
        }
    }

    uiAlert(tips || '操作失败', callback)
}