import { resourceServer, designWidth } from '@/config/common'
import { strTrimRight } from '@/filters/str_trim_right'
import apiConfig from '../config/api_config'
import { isApiResult, isArray, isBoolean, isEmpty, isNumber, isObject, isString, isVue } from './assert'
import { datetimeFormat } from './datetime'
import { apiGet } from './request'
import { localStorageGet, localStorageSet } from './storage'
import { ensureLeft, substringBefore, substringAfter } from './string'
import { uiShowErrorTips, uiAlert } from './ui'
import BigNumber from 'bignumber.js'

// noinspection JSUnusedGlobalSymbols
/**
 * @param evt
 * @returns {*}
 */
export const getSrcElementFromEvent = evt => {
    // noinspection JSDeprecatedSymbols
    return evt.target ? evt.target : evt.srcElement
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param dom
 * @returns {any}
 */
export const getDataSetFromDomElement = dom => {
    return isObject(dom.dataset) ? dom.dataset : {}
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param msecs
 * @returns {Promise<*>}
 */
export const delayAsync = msecs => {
    return new Promise(resolve => setTimeout(resolve, msecs))
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param params
 * @returns {*}
 */
export const handleSearchParams = params => {
    const map1 = { ...params }

    if ('d1' in map1) {
        map1.d1 = datetimeFormat(map1.d1, 'Y-m-d')
    }

    if ('d2' in map1) {
        map1.d1 = datetimeFormat(map1.d1, 'Y-m-d')
    }

    for (let pn in map1) {
        // noinspection JSUnfilteredForInLoop
        if (isBoolean(map1[pn])) {
            // noinspection JSUnfilteredForInLoop
            delete map1[pn]
            continue
        }

        // noinspection JSUnfilteredForInLoop
        if (isString(map1[pn]) && map1[pn] === '') {
            // noinspection JSUnfilteredForInLoop
            delete map1[pn]
            continue
        }

        // noinspection JSUnfilteredForInLoop
        if (isNumber(map1[pn]) && map1[pn] === -255) {
            // noinspection JSUnfilteredForInLoop
            delete map1[pn]
            continue
        }

        // noinspection JSUnfilteredForInLoop
        if (pn === 'page' && (!isNumber(map1[pn]) || map1[pn] < 2)) {
            // noinspection JSUnfilteredForInLoop
            delete map1[pn]
        }

        // noinspection JSUnfilteredForInLoop
        if (pn === 'pageSize' && (!isNumber(map1[pn]) || map1[pn] < 1)) {
            // noinspection JSUnfilteredForInLoop
            delete map1[pn]
        }
    }

    return map1
}

/**
 * @param arg0
 * @returns {string}
 */
export const qiniuResource = arg0 => {
    if (!isString(arg0) || arg0 === '') {
        return ''
    }

    if (arg0.toLowerCase().startsWith('https://') ||
        arg0.toLowerCase().startsWith('http://') ||
        arg0.indexOf('../') !== -1) {
        return arg0
    }

    let prefix = ensureLeft(resourceServer)

    if (prefix !== '/' && prefix.endsWith('/')) {
        prefix = strTrimRight(prefix, '/')
    }

    return prefix + ensureLeft(arg0, '/')
}

/**
 * @param arg0
 * @param args
 * @returns {string}
 */
export const qiniuImageView = (arg0, ...args) => {
    if (!isString(arg0) || arg0 === '') {
        return ''
    }

    if (arg0.toLowerCase().startsWith('https://') ||
        arg0.toLowerCase().startsWith('http://') ||
        arg0.indexOf('../') !== -1) {
        return arg0
    }

    let mode = null
    let width = null
    let height = null
    let opts = null

    for (let arg of args) {
        if (isNumber(arg) && arg >= 0) {
            if (mode === null) {
                mode = arg
            } else if (width === null) {
                width = arg
            } else if (height === null) {
                height = arg
            }

            continue
        }

        if (isObject(arg) && opts === null) {
            opts = arg
        }
    }

    if (mode === null || (width === null && height === null)) {
        return arg0
    }

    const sb = [
        qiniuResource(arg0),
        '?imageView2',
        `/${mode}`
    ]

    if (isNumber(width) && width > 0) {
        sb.push(`/w/${width}`)
    }

    if (isNumber(height) && height > 0) {
        sb.push(`/h/${height}`)
    }

    if (opts === null) {
        opts = {}
    }

    for (let pn in opts) {
        if (pn === 'q') {
            continue
        }

        sb.push(`/${pn}/${opts[pn]}`)
    }

    sb.push('/q/100!')
    return sb.join('')
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {string}
 */
export const parseStyleObject = arg0 => {
    if (!isObject(arg0) || isEmpty(arg0)) {
        return ''
    }

    const sb = []

    for (let pn in arg0) {
        const chars = []

        // noinspection JSUnfilteredForInLoop
        for (let c of [...pn]) {
            if (c.toUpperCase() === c) {
                chars.push('-')
            }

            chars.push(c.toLowerCase())
        }

        // noinspection JSUnfilteredForInLoop
        sb.push(`${chars.join('')}: ${arg0[pn]}`)
    }

    return sb.length < 1 ? '' : `${sb.join('; ')};`
}

// noinspection JSUnusedGlobalSymbols
/**
 * @returns {Promise<*>}
 */
export const getChinaRegions = async () => {
    const items = localStorageGet('chinaRegions')

    if (isArray(items) && !isEmpty(items)) {
        return Promise.resolve(items)
    }

    let apiResult

    try {
        apiResult = await apiGet('ChinaRegions')
    } catch (ex) {
        apiResult = null
    }

    if (!isApiResult(apiResult)) {
        return Promise.resolve([])
    }

    // noinspection JSObjectNullOrUndefined
    localStorageSet('chinaRegions', apiResult.list)
    return Promise.resolve(apiResult.list)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {boolean}
 */
export const hasException = (...args) => {
    let vm = null
    let apiResult = null
    let showErrorTips = null

    for (let arg of args) {
        if (isVue(arg)) {
            vm === null ? vm = arg : false
            continue
        }

        if (isApiResult(arg)) {
            apiResult === null ? apiResult = arg : false
            continue
        }

        if (isBoolean(arg) && showErrorTips === null) {
            showErrorTips = arg
        }
    }

    if (apiResult === null) {
        return false
    }

    if (showErrorTips === null) {
        showErrorTips = true
    }

    const { errno, error } = apiResult

    if (errno === apiConfig.errno.apiCallException) {
        showErrorTips && uiShowErrorTips(error || '接口请求出错')
        return true
    }

    let errorCodes = [0, apiConfig.errno.dataValidationError]

    if (errorCodes.includes(errno)) {
        return false
    }

    errorCodes = [
        apiConfig.errno.requireAccessToken,
        apiConfig.errno.invalidAccessToken,
        apiConfig.errno.accessTokenExpired,
        apiConfig.errno.tokenRefreshException
    ]

    if (errorCodes.includes(errno)) {
        // noinspection JSUnresolvedVariable
        showErrorTips && uiShowErrorTips('安全令牌已失效<br>请重新登录', () => vm && vm.$router.replace({ name: 'login' }))
        return true
    }

    if (isString(error) && error !== '') {
        showErrorTips && uiShowErrorTips(error)
        return true
    }

    return false
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {{}|null}
 */
export const getComponent = (...args) => {
    let vm = null
    let name = ''

    for (let arg of args) {
        if (isVue(arg)) {
            vm === null ? vm = arg : false
            continue
        }

        if (isString(arg) && arg !== '' && !name) {
            name = arg
        }
    }

    if (vm === null || !name) {
        return null
    }

    let refs

    try {
        // noinspection JSUnresolvedVariable
        refs = vm.$refs
    } catch (ex) {
        refs = null
    }

    if (!refs) {
        return null
    }

    if (refs[name]) {
        return refs[name]
    }

    let component

    try {
        // noinspection JSUnresolvedVariable
        component = vm.$parent
    } catch (ex) {
        component = null
    }

    if (!component) {
        return null
    }

    for (let i = 1; i <= 9; i++) {
        try {
            refs = component.$refs
        } catch (ex) {
            refs = null
        }

        if (!refs) {
            return null
        }

        if (refs[name]) {
            return refs[name]
        }

        try {
            component = component.$parent
        } catch (ex) {
            component = null
        }

        if (!component) {
            return null
        }
    }

    return null
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param args
 * @returns {{}}
 */
export const buildApiResult = (...args) => {
    let errno = 0
    let error = ''
    const validationErrors = {}
    let obj = {}
    let list = []
    const pagination = {}

    if (args.length == 1) {
        if (isObject(args[0])) {
            obj = args[0]
        } else if (isArray(args[0])) {
            list = args[0]
        }
    } else if (args.length == 2) {
        if (isNumber(args[0]) && isString(args[1])) {
            errno = args[0]
            error = args[1]
        } else if (isObject(args[0]) && isArray(args[1])) {
            obj = args[0]
            list = args[1]
        }
    }

    return { errno, error, validationErrors, obj, list, pagination }
}

// noinspection JSUnusedGlobalSymbols
/**
 * @returns {number}
 */
export const getWindowHeight = () => {
    let n1 = 667

    if ('innerHeight' in window) {
        n1 = parseInt(`${window.innerHeight}`)
    } else if (document.body && document.body.clientHeight) {
        n1 = parseInt(`${document.body.clientHeight}`)
    }

    return n1
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @param arg1
 * @param precision
 * @returns {number}
 */
export const px2vw = (arg0, arg1 = designWidth, precision = 5) => {
    if (!isNumber(arg0)) {
        return 0
    }

    const n1 = (new BigNumber(arg1)).div(100)
    const n2 = (new BigNumber(arg0)).div(n1)
    const s1 = n2.toFixed(precision)
    const p1 = substringBefore(s1, '.')
    const p2 = substringAfter(s1, '.').replace(/[0]+$/, '')
    return p2 === '' ? Number(p1) : Number(`${p1}.${p2}`)
}
export const str2timestamp = str => {
    let ts = parseInt(Date.parse(str) / 1000)

    if (isNaN(ts)) {
        ts = parseInt(Date.parse(new Date(str.replace(/-/g, '/'))) / 1000)
    }

    return isNaN(ts) ? 0 : ts
}

//正则给富文本img加calss
export const replaceImg = (text) => {
    if (isEmpty(text)) {
        return "";
    }
    let stext = text.replace(/<img/gi, "<img class='rt-img' style='display: block;max-width: 100%;margin: 0 auto;' ");
    return stext;
};

export default {
    qiniuResource,
    qiniuImageView,
    str2timestamp,
    hasException,
    replaceImg
}