<template>
  <div
    class="wrapper"
    :class="{hide: !show}"
    :style="wrapperStyle"
    data-flex="dir:top main:center cross:center"
  >
    <div data-flex-box="0">
      <div class="yuan" data-flex="main:center cross:center">
        <i class="mg-icon mg-icon-question-circle-fill" data-flex-box="0"></i>
      </div>
      <div class="tips" v-html="tips"></div>
      <div class="btns" data-flex="">
        <div class="confirm" data-flex-box="0" data-flex="main:center cross:center" @click="btnClick(1)">
          <i data-flex-box="0">{{ btn1Text }}</i>
        </div>
        <div data-flex-box="0" data-flex="main:center cross:center" @click="btnClick(2)">
          <i data-flex-box="0">{{ btn2Text }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customEvents from '@/config/custom_events'
import bus from '@/libs/bus'
import {getRandomString} from '@/utils/string'
import {getWindowHeight} from '@/utils/common'

const defaultBtn1Text = '确定'
const defaultBtn2Text = '取消'
const defaultZIndex = 8888

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MgConfirm',
  data() {
    return {
      windowHeight: 667,
      show: false,
      tips: '',
      btn1Text: defaultBtn1Text,
      btn1Callback: null,
      btn2Text: defaultBtn2Text,
      btn2Callback: null,
      zIndex: defaultZIndex
    }
  },
  computed: {
    wrapperStyle() {
      return {
        height: `${this.windowHeight}px`,
        zIndex: this.zIndex
      }
    }
  },
  methods: {
    btnClick(num) {
      this.show = false
      document.getElementById('app').dataset.dialogId = ''
      const btnCallback = num === 1 ? this.btn1Callback: this.btn2Callback

      if (!btnCallback) {
        return
      }

      this.$nextTick(() => btnCallback())
    }
  },
  created() {
    this.windowHeight = getWindowHeight()
  },
  mounted() {
    bus.$on(customEvents['ConfirmDialog.show'], (message = {}) => {
      const el = document.getElementById('app')
      const id = el.dataset.dialogId

      if (id) {
        return
      }

      const {tips, btn1Text, btn1Callback, btn2Text, btn2Callback, zIndex} = message

      if (!tips) {
        return
      }

      this.show = true
      this.tips = tips
      this.btn1Text = btn1Text || defaultBtn1Text
      this.btn1Callback = btn1Callback || null
      this.btn2Text = btn2Text || defaultBtn2Text
      this.btn2Callback = btn2Callback || null
      this.zIndex = zIndex || defaultZIndex
      el.dataset.dialogId = getRandomString(8, 2)
    })
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  background: rgba(37, 38, 45, .4);
  position: fixed;
  top: 0;

  &.hide {
    display: none;
  }

  > div {
    width: 600px;
    max-height: 1200px;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
  }

  .yuan {
    width: 128px;
    height: 128px;
    background: #f3f4f5;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 40px;
    overflow: hidden;

    > i {
      font-size: 42px;
      font-style: normal;
      color: #666;
    }
  }

  .tips {
    font-size: 14px;
    color: #666;
    line-height: 1.4;
    padding: 10px 20px 40px 10px;
    text-align: center;
  }

  .btns {
    border-top: 1px solid #d3d3d3; /* px-to-viewport-ignore */

    > div {
      box-sizing: border-box;
      width: 50%;
      height: 100px;
      background: #f3f3f3;
      font-size: 16px;

      &.confirm {
        color: #fc962d;
      }

      &:nth-child(2) {
        border-left: 1px solid #d3d3d3; /* px-to-viewport-ignore */
      }
    }
  }
}
</style>