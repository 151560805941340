import BigNumber from 'bignumber.js'
import {isDate, isEmpty, isNumber, isString} from './assert'
import {splitByChars, substringBefore} from './string'

/**
 * @param arg0
 * @returns {Date}
 */
export const toDate = arg0 => {
    if (arg0 instanceof Date) {
        return arg0
    }

    if (!isString(arg0) || isEmpty(arg0)) {
        return null
    }

    let [year, month, day, hour, minute, second] = splitByChars(arg0, ['-', '/', ' ', ':']).map(it => {
        return parseInt(it)
    }).filter(it => !isNaN(it) && it >= 0)

    if (!isNumber(year) || year < 1970 ||
        !isNumber(month) || month < 1 || month > 12 ||
        !isNumber(day) || day < 1 || day > 31) {
        return null
    }

    if (!isNumber(hour)) {
        hour = 0
    } else if (hour < 0 || hour > 23) {
        return null
    }

    if (!isNumber(minute)) {
        minute = 0
    } else if (minute < 0 || minute > 59) {
        return null
    }

    if (!isNumber(second)) {
        second = 0
    } else if (second < 0 || second > 59) {
        return null
    }

    const daysOfMonth = ['', 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (day > daysOfMonth[month]) {
        return null
    }

    const isLeapYear = (((year % 4) === 0) && ((year % 100) !== 0) || ((year % 400) === 0))

    if (month === 2 && !isLeapYear && day > 28) {
        return null
    }

    return new Date(year, month - 1, day, hour, minute, second)
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {Date}
 */
export const toShortDate = arg0 => {
    const d1 = toDate(arg0)

    if (d1 === null) {
        return null
    }

    return new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {number}
 */
export const toTimestamp = arg0 => {
    const d1 = toDate(arg0)

    if (!d1) {
        return 0
    }

    try {
        const n1 = new BigNumber(d1.getTime()).div(1000)
        return parseInt(substringBefore(n1.toFixed()))
    } catch (ex) {
        return 0
    }
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {number}
 */
export const toMilliSeconds = arg0 => {
    try {
        return toDate(arg0).getTime()
    } catch (ex) {
        return 0
    }
}

/**
 * @param arg0
 * @param format
 * @returns {string}
 */
export const datetimeFormat = (arg0, format) => {
    if (!isString(format) || isEmpty(format)) {
        format = 'Y-m-d H:i:s'
    }

    let d1

    if (isString(arg0)) {
        d1 = toDate(arg0)
    } else {
        d1 = arg0
    }

    if (!isDate(d1)) {
        return ''
    }

    const year = `${d1.getFullYear()}`
    const month = d1.getMonth() + 1
    const date = d1.getDate()
    const hours = d1.getHours()
    const minutes = d1.getMinutes()
    const seconds = d1.getSeconds()
    const weekDayIdx = d1.getDay()
    const weekDayNames = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
    const sb = []

    for (let i = 0; i < format.length; i++) {
        const c1 = format.charAt(i)

        switch (c1) {
            case 'Y':
                sb.push(year)
                break
            case 'y':
                sb.push(year.substring(2))
                break
            case 'm':
                sb.push(month < 10 ? `0${month}` : month)
                break
            case 'n':
                sb.push(month)
                break
            case 'd':
                sb.push(date < 10 ? `0${date}` : date)
                break
            case 'j':
                sb.push(date)
                break
            case 'H':
                sb.push(hours < 10 ? `0${hours}` : hours)
                break
            case 'i':
                sb.push(minutes < 10 ? `0${minutes}` : minutes)
                break
            case 's':
                sb.push(seconds < 10 ? `0${seconds}` : seconds)
                break
            case 'w':
                sb.push(weekDayIdx)
                break
            case 'l':
                sb.push(weekDayNames[weekDayIdx])
                break
            default:
                sb.push(c1)
                break
        }
    }

    return sb.join('')
}