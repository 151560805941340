import {datetimeFormat as dtf} from '@/utils/datetime'

export const datetimeFormat = (value, ...args) => {
	let format = 'Y-m-d H:i:s'

	if (args.length > 0) {
		format = args[0]
	}

	return dtf(value, format)
}