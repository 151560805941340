<template>
  <div id="app" data-dialog-id="" data-loading-id="">
    <router-view />
    <mg-loading />
    <mg-alert />
    <mg-confirm />
  </div>
</template>

<script>
import MgLoading from '@/components/common/mg-loading'
import MgAlert from '@/components/common/mg-alert'
import MgConfirm from '@/components/common/mg-confirm'

export default {
  name: 'App',
  components: {MgConfirm, MgAlert, MgLoading}
}
</script>

<style lang="less">
body {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
</style>