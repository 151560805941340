import { splitByChars } from './string'

/**
 * @param arg0
 * @returns {boolean}
 */
export const isObject = arg0 => {
    return arg0 !== null && typeof arg0 === 'object'
}

/**
 * @param arg0
 * @returns {boolean}
 */
export const isArray = arg0 => {
    if (arg0 === null || typeof arg0 === 'undefined') {
        return false
    }

    return Object.prototype.toString.call(arg0) === '[object Array]'
}

/**
 * @param arg0
 * @returns {boolean}
 */
export const isFunction = arg0 => {
    return typeof arg0 === 'function'
}

/**
 * @param arg0
 * @returns {boolean|boolean}
 */
export const isNumber = arg0 => {
    return typeof arg0 === 'number' && !isNaN(arg0)
}

/**
 * @param arg0
 * @returns {boolean}
 */
export const isBoolean = arg0 => {
    return typeof arg0 === 'boolean'
}

/**
 * @param arg0
 * @returns {boolean}
 */
export const isString = arg0 => {
    return typeof arg0 === 'string'
}

/**
 * @param arg0
 * @returns {boolean}
 */
export const isEmpty = arg0 => {
    if (arg0 === null || typeof arg0 === 'undefined') {
        return true
    }

    if (isString(arg0)) {
        return arg0 === ''
    }

    if (isArray(arg0)) {
        return arg0.length < 1
    }

    if (isObject(arg0)) {
        // noinspection LoopStatementThatDoesntLoopJS
        for (let pn in arg0) {
            return false
        }

        return true
    }

    return false
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {boolean}
 */
export const isApiResult = arg0 => {
    return isObject(arg0) &&
        'errno' in arg0 &&
        'error' in arg0 &&
        'validationErrors' in arg0 &&
        'obj' in arg0 &&
        'list' in arg0 &&
        'pagination' in arg0
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {boolean}
 */
export const isVue = arg0 => {
    try {
        // noinspection JSUnresolvedVariable
        return arg0._isVue === true
    } catch (ex) {
        return false
    }
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {boolean}
 */
export const isEvent = arg0 => {
    try {
        return arg0.constructor.name.toLowerCase().endsWith('event')
    } catch (ex) {
        return false
    }
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {boolean}
 */
export const isDate = arg0 => {
    if (!arg0) {
        return false
    }

    if (arg0 instanceof Date) {
        return true
    }

    if (!isString(arg0)) {
        return false
    }

    const parts = splitByChars(arg0, ['-', '/']).map(it => {
        return parseInt(it)
    }).filter(it => isNumber(it) && it >= 0)

    if (parts.length !== 3) {
        return false
    }

    const [year, month, day] = parts

    if (!isNumber(year) || year < 1000 ||
        !isNumber(month) || month < 1 || month > 12 ||
        !isNumber(day) || day < 1 || day > 31) {
        return false
    }

    const daysOfMonth = ['', 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (day > daysOfMonth[month]) {
        return false
    }

    const isLeapYear = (((year % 4) === 0) && ((year % 100) !== 0) || ((year % 400) === 0))

    // noinspection RedundantIfStatementJS
    if (month === 2 && !isLeapYear && day > 28) {
        return false
    }

    return true
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param arg0
 * @returns {boolean}
 */
export const isDateTime = arg0 => {
    if (!arg0) {
        return false
    }

    if (arg0 instanceof Date) {
        return true
    }

    if (!isString(arg0)) {
        return false
    }

    const parts = splitByChars(arg0, ['-', '/', ' ', ':']).map(it => {
        return parseInt(it)
    }).filter(it => isNumber(it) && it >= 0)

    const [year, month, day, hour, minute, second] = parts

    if (!isNumber(year) || year < 1000 ||
        !isNumber(month) || month < 1 || month > 12 ||
        !isNumber(day) || day < 1 || day > 31 ||
        !isNumber(hour) || hour < 0 || hour > 23 ||
        !isNumber(minute) || minute < 0 || minute > 59) {
        return false
    }

    if (isNumber(second) && (second < 0 || second > 59)) {
        return false
    }

    const daysOfMonth = ['', 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (day > daysOfMonth[month]) {
        return false
    }

    const isLeapYear = (((year % 4) === 0) && ((year % 100) !== 0) || ((year % 400) === 0))

    // noinspection RedundantIfStatementJS
    if (month === 2 && !isLeapYear && day > 28) {
        return false
    }

    return true
}

export default {
    isEmpty,
    isArray,
    isObject
}
