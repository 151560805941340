let s1, s2, s3, s4

switch (process.env.VUE_APP_MODE) {
    case 'prod':
        s1 = '/'
        s2 = ''
        s3 = 'prod'
        s4 = 'http://service.ava.com.cn/api/wxgzh/authorize'
        break
    case 'ppe':
        s1 = '/'
        s2 = 'http://service.ava.com.cn/api'
        s3 = 'ppe'
        s4 = 'http://service.ava.com.cn/api/wxgzh/authorize'
        break
    default:
        s1 = ''
        s2 = 'http://127.0.0.1:3508'
        s3 = 'dev'
        s4 = 'http://service.ava.com.cn/api/wxgzh/authorize'
        break
}

// noinspection JSUnresolvedVariable
export const baseUrl = s1

// noinspection JSUnresolvedVariable
export const mwebBaseUrl = s2

// noinspection JSUnresolvedVariable
export const appEnv = s3

// noinspection JSUnresolvedVariable
export const resourceServer = 'https://h5.mingfushiye.cn/'

// noinspection JSUnresolvedVariable
export const defaultPageTitle = '纽扣'

// noinspection JSUnresolvedVariable
export const defaultPageBackgroundColor = '#f8f8f8'

// noinspection JSUnresolvedVariable
export const designWidth = 750

// noinspection JSUnresolvedVariable
export const wxuserMock = {
    enable: appEnv === 'dev',
    data: {
        // memberId: 50002,
        memberId: 0,
        openid: 'ol3Hi5bKYaoEGWMIoSOHPEg51D7g2',
        nickname: '小卡拉米',
        avatarUrl: 'https://thirdwx.qlogo.cn/mmopen/vi_32/XHLkzsSpU84HxOpeYFk0bRgc5Fe7WxbNcibU5NDcdzia51MQt0VDibicFcWfuaYAUw4TGmFqcR97wIECibe9yicmpOWw/132'
    }
}


// noinspection JSUnresolvedVariable
export const wxgzhAuthorizeUrl = s4

// noinspection JSUnusedGlobalSymbols
export const gzhSettings = {
    name: 'xxx',
    appid: 'xxx'
}