/**
 * @param key
 * @returns {*}
 */
export const localStorageGet = key => {
    let item

    try {
        item = JSON.parse(localStorage.getItem(key))
    } catch (ex) {
        item = null
    }

    return item === null || (typeof item) !== 'object' || !('data' in item) ? null : item.data
}

/**
 * @param key
 * @param val
 */
export const localStorageSet = (key, val) => {
    const type = typeof val
    const supportedTypes = ['boolean', 'string', 'number', 'object']
    const isArray = type === 'object' && val.constructor === Array
    const data = val !== null && (isArray || supportedTypes.includes(type)) ? val : null

    try {
        localStorage.setItem(key, JSON.stringify({data}))
    } catch (ex) {}
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param key
 */
export const localStorageRemove = key => {
    try {
        localStorage.removeItem(key)
    } catch (ex) {}
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param key
 * @returns {*}
 */
export const sessionStorageGet = key => {
    let item

    try {
        item = JSON.parse(sessionStorage.getItem(key))
    } catch (ex) {
        item = null
    }

    return item === null || (typeof item) !== 'object' || !('data' in item) ? null : item.data
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param key
 * @param val
 */
export const sessionStorageSet = (key, val) => {
    const type = typeof val
    const supportedTypes = ['boolean', 'string', 'number', 'object']
    const isArray = type === 'object' && val.constructor === Array
    const data = val !== null && (isArray || supportedTypes.includes(type)) ? val : null

    try {
        sessionStorage.setItem(key, JSON.stringify({data}))
    } catch (ex) {}
}

// noinspection JSUnusedGlobalSymbols
/**
 * @param key
 */
export const sessionStorageRemove = key => {
    try {
        sessionStorage.removeItem(key)
    } catch (ex) {}
}