import VueRouter from 'vue-router'
import routes from './routes'
import {baseUrl} from '@/config/common'

const opts = {
	mode: 'history',
	routes
}

if (baseUrl) {
    opts.base = baseUrl
}

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default new VueRouter(opts)