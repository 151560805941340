import {isEmpty, isNumber} from './assert'

// noinspection JSUnusedGlobalSymbols
/**
 * @param under
 * @param over
 * @returns {number}
 */
export const getRandomNumber = (under, over) => {
    return parseInt(Math.random() * (over - under + 1) + under)
}

/**
 * @param num
 * @param n
 * @returns {string}
 */
export const toDecimalString = (num, n) => {
    if (!isNumber(num)) {
        try {
            num = parseFloat(`${num}`)
        } catch (ex) {
            num = null
        }
    }

    if (!isNumber(num) || num === 0) {
        return '0.00'
    }

    if (!isNumber(n) || n < 1) {
        n = 2
    }

    const parts = `${num}`.split('.')
    const p2 = parts.length > 1 ? parts[1].substring(0, n) : ''
    const ret = `${parts[0]}.${p2}`.replace(/^0+/, '0').replace(/0+$/, '').replace(/\.$/, '')
    return isEmpty(ret) || ret === '0' ? '0.00' : ret
}